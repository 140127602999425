import React, { FC, SVGProps } from "react";

const DownArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.9396 6.71252L10.0496 11.6025C9.47207 12.18 8.52707 12.18 7.94957 11.6025L3.05957 6.71252"
      stroke="#666666"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrowIcon;
