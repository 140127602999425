import React, { FC } from "react";
import { blackContrast, whiteContrast } from "utils/colorsHelpers";
import SuggestionCard, { ISuggestionCardProps } from "./SuggestionCard";

interface ISuggestionCardLayoutProps {
  cards: ISuggestionCardProps[];
}

const SuggestionCardLayout: FC<ISuggestionCardLayoutProps> = ({ cards }) => {
  return (
    <div
      className="mt-5 grid grid-cols-2 grid-rows-2 gap-2.5 md:grid-cols-3 md:grid-rows-1"
      data-testid="suggestions-layout"
    >
      {cards.map(({ number, text, icon, backgroundColor, cardId, onFacetSelected, borderStyle }, index) => {
        return (
          <div
            key={index}
            className={`${cardId === "first-card" ? "row-span-2 md:row-span-1" : ""} ${
              blackContrast(backgroundColor) > whiteContrast(backgroundColor) ? "text-dark-grey" : "text-white"
            }`}
            onClick={() => {
              onFacetSelected?.();
            }}
          >
            <SuggestionCard text={text} number={number} icon={icon} cardId={cardId} borderStyle={borderStyle} />
          </div>
        );
      })}
    </div>
  );
};

export default SuggestionCardLayout;
