import { useTranslation } from "next-i18next";
import React, { FC } from "react";

interface IListingSiteTitle {
  whiteText: boolean;
}

const ListingSiteTitle: FC<IListingSiteTitle> = ({ whiteText }) => {
  const { t: translate } = useTranslation("listingsite");

  return (
    <div className="py-5">
      <span className={`text-[34px] font-bold leading-10 ${whiteText ? "text-white" : "text-dark-grey"} `}>
        {translate("first.page.title_text", "Your next career awaits")}
      </span>
    </div>
  );
};

export default ListingSiteTitle;
