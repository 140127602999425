import React, { FC, SVGProps } from "react";

const FilterIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.2576 19.07C15.2576 19.68 14.8575 20.48 14.3475 20.79L12.9376 21.7C11.6276 22.51 9.80756 21.6 9.80756 19.98V14.63C9.80756 13.92 9.40756 13.01 8.99756 12.51L5.15753 8.47C4.64753 7.96 4.24756 7.06001 4.24756 6.45001V4.13C4.24756 2.92 5.15758 2.01001 6.26758 2.01001H19.6075C20.7175 2.01001 21.6276 2.92 21.6276 4.03V6.25C21.6276 7.06 21.1176 8.07001 20.6176 8.57001"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0076 16.52C18.7749 16.52 20.2076 15.0873 20.2076 13.32C20.2076 11.5527 18.7749 10.12 17.0076 10.12C15.2403 10.12 13.8076 11.5527 13.8076 13.32C13.8076 15.0873 15.2403 16.52 17.0076 16.52Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8076 17.12L19.8076 16.12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
