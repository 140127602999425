import React from "react";
import Logo from "components/Logo";
import { useTranslation } from "next-i18next";

const NotFound: React.FC = () => {
  const { t: translate } = useTranslation("common");

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center p-4 text-center">
      <div className="mb-12 h-32 w-32 rounded-lg p-3">
        <Logo
          logoImage={
            "https://adway-assets.s3.eu-north-1.amazonaws.com/images/engage-logos/1671199468636-adway-favicon-deep-purple-rgb.png"
          }
          shape="square"
        />
      </div>
      <span className="text-text-gray text-4xl font-medium">404</span>
      <p className="text-text-gray mt-4 text-lg font-bold">
        {translate("not_found.text", "The page you requested was not found")}
      </p>
    </div>
  );
};

export default NotFound;
