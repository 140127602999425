import React, { FC, ReactNode } from "react";

export interface ISuggestionCardProps {
  number: number | string | ReactNode;
  text?: string;
  backgroundColor?: string;
  icon: JSX.Element;
  cardId: "first-card" | "second-card" | "third-card";
  onFacetSelected?: () => void;
  borderStyle: string;
}

const SuggestionCard: FC<ISuggestionCardProps> = ({ number, text, icon, cardId, borderStyle }) => {
  const isRemoteJobs = cardId === "first-card";
  const cardFlex = isRemoteJobs ? "flex flex-col md:flex" : "flex";

  const cardColor =
    cardId === "first-card"
      ? "bg-first-card active:bg-first-card-dark hover:bg-first-card-hover"
      : cardId === "second-card"
      ? "bg-second-card active:bg-second-card-dark hover:bg-second-card-hover"
      : "bg-third-card active:bg-third-card-dark hover:bg-third-card-hover";

  return (
    <div
      className={`${cardColor} ${cardFlex} flex h-full items-center ${
        isRemoteJobs && "justify-center"
      } ${borderStyle} p-5 hover:cursor-pointer md:flex-col`}
      data-testid={`suggestion-card-${cardId}`}
    >
      <div className={`${isRemoteJobs ? "m-5 md:ml-5" : "mr-5 md:mr-0"} md:mb-2.5 md:ml-0 md:mt-5`}>{icon}</div>
      <div className={`${isRemoteJobs && "text-center"} md:text-center`}>
        <div
          className="text-2xl font-semibold"
          data-testid={`suggestion-card-${text?.toLowerCase().replace(/\s/g, "")}-number`}
        >
          {number}
        </div>
        <div
          className="text-sm leading-6"
          data-testid={`suggestion-card-${text?.toLowerCase().replace(/\s/g, "")}-text`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default SuggestionCard;
