import { useSearchBox } from "react-instantsearch";
import SearchIcon from "../icons/SearchIcon";
import { FC } from "react";
import { useTranslation } from "next-i18next";

interface IJobSearchFieldProps {
  borderStyle: string;
}

const JobSearchField: FC<IJobSearchFieldProps> = ({ borderStyle }) => {
  const { query, refine } = useSearchBox();
  const { t: translate } = useTranslation("listingsite");

  return (
    <div className="relative w-full">
      {!query && <SearchIcon className="absolute  ml-5 mt-6 h-7 w-7 text-primary-listing-site" />}
      <input
        className={`${borderStyle} h-20 w-full shadow-inner ${
          query ? "px-5" : "px-16"
        } placeholder:ml-3 focus:outline-none`}
        placeholder={translate("search.bar_text")}
        value={query}
        onChange={e => refine(e.target.value)}
        style={{
          fontSize: "16px",
        }}
      />
    </div>
  );
};

export default JobSearchField;
