import React, { FC } from "react";
import { blackContrast, whiteContrast } from "utils/colorsHelpers";

interface ICheckboxProps {
  checked: boolean;
  onClick?: () => void;
  color: string | undefined;
  id: string;
  borderStyle?: string;
}

const Checkbox: FC<ICheckboxProps> = ({ checked, onClick, color, id, borderStyle }) => {
  const getColor = (color: string | undefined): string =>
    blackContrast(color) > whiteContrast(color)
      ? "checkmark-black border-black checked:border-black hover:border-black checked:hover:border-black checked:focus:border-black accent-black"
      : "border-primary-listing-site ";

  return (
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onClick={() => onClick?.()}
      className={`${borderStyle} h-6 w-6 rounded-md border-2 checked:bg-primary-listing-site hover:cursor-pointer checked:hover:bg-primary-listing-site focus:ring-transparent checked:focus:bg-primary-listing-site ${getColor(
        color
      )}}`}
    />
  );
};

export default Checkbox;
