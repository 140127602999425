import React, { FC } from "react";
import LeftArrowIcon from "../icons/LeftArrowIcon";
import { useClearRefinements, useInstantSearch, useSearchBox } from "react-instantsearch";

interface IGoHomeProps {
  onClick: () => void;
  whiteText: boolean;
}
const GoHome: FC<IGoHomeProps> = ({ onClick, whiteText }) => {
  const { refine: refineClearRefinements } = useClearRefinements();
  const { refine: refineClearQuery } = useSearchBox();
  const { indexUiState } = useInstantSearch();
  const showArrow = indexUiState.refinementList || indexUiState.configure?.aroundLatLng || indexUiState.query;

  return showArrow ? (
    <div className="mt-8">
      <LeftArrowIcon
        onClick={() => {
          refineClearRefinements();
          refineClearQuery("");
          onClick();
        }}
        cursor="pointer"
        color={`${whiteText ? "white" : "#4F4F4F"}`}
      />
    </div>
  ) : null;
};
export default GoHome;
