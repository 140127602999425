import Link from "next/link";
import React, { FC } from "react";
import RightArrowIcon from "../icons/RightArrowIcon";
import { blackContrast, whiteContrast } from "utils/colorsHelpers";

interface IAboutUsCardProps {
  title: string;
  description: string;
  query?: string;
  refinementList?: { [attribute: string]: string[] };
  aroundLatLng?: string;
  customerSlug: string;
  backgroundColor: string;
  borderStyle: string;
}

const AboutUsCard: FC<IAboutUsCardProps> = ({
  title,
  description,
  query,
  refinementList,
  aroundLatLng,
  customerSlug,
  backgroundColor,
  borderStyle,
}) => {
  const color = blackContrast(backgroundColor) > whiteContrast(backgroundColor) ? "text-dark-grey" : "text-white";

  return !(query || refinementList || aroundLatLng) ? (
    <Link href={`${customerSlug}/about`}>
      <div
        className={`${borderStyle} cursor-pointer break-words border bg-about-us-color px-5 py-6 hover:bg-about-us-hover-color active:bg-about-us-active-color
        ${color}`}
        data-testid="about-us-card"
      >
        <h3 className={`m-0 font-semibold leading-6 ${color}`}>{title}</h3>
        <p className={`mb-2.5 text-sm leading-6 ${color}`}>{description}</p>
        <RightArrowIcon />
      </div>
    </Link>
  ) : null;
};

export default AboutUsCard;
