import React, { useMemo, FC, Dispatch, SetStateAction } from "react";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import JobChip from "./JobChip";
import { RefinableAttribute } from "hooks/useRefinements";
import { useTranslation } from "next-i18next";

export interface IJobQuerySuggestionsProps {
  onRefine: (attribute: RefinableAttribute, value: string) => void;
  cityItems: RefinementListItem[];
  categoryItems: RefinementListItem[];
  query?: string | undefined;
  refinementList?: { [attribute: string]: string[] } | undefined;
  aroundLatLng?: string | undefined;
  onSetSelectedCategories: Dispatch<SetStateAction<string[]>>;
  onSetSelectedCities: Dispatch<SetStateAction<string[]>>;
  whiteText?: boolean;
  facetsColors: { locationFacet: string; categoryFacet: string; otherFacet: string };
  borderStyle: string;
}

const JobQuerySuggestions: FC<IJobQuerySuggestionsProps> = ({
  onRefine,
  cityItems,
  categoryItems,
  query,
  refinementList,
  aroundLatLng,
  onSetSelectedCategories,
  onSetSelectedCities,
  whiteText,
  facetsColors,
  borderStyle,
}) => {
  const { t: translate } = useTranslation("listingsite");

  const suggestedCities = useMemo(() => {
    const numberOfItemsToSuggest = 3 - (cityItems.filter(item => item.isRefined)?.length || 0);
    return cityItems.filter(loc => !loc.isRefined).slice(0, numberOfItemsToSuggest);
  }, [cityItems]);

  const suggestedCategories = useMemo(() => {
    const numberOfItemsToSuggest = 3 - (categoryItems.filter(item => item.isRefined)?.length || 0);
    return categoryItems.filter(loc => !loc.isRefined).slice(0, numberOfItemsToSuggest);
  }, [categoryItems]);

  return query || refinementList || aroundLatLng ? (
    <>
      <div>
        {suggestedCities?.length || suggestedCategories?.length ? (
          <h5 className={`mb-3 mt-2.5 text-sm font-medium ${whiteText ? "text-white" : "text-dark-grey"}`}>
            {translate("joblist.are_you_looking_for_jobs_in_text")}
          </h5>
        ) : (
          ""
        )}
        {suggestedCategories?.length ? (
          <div data-testid="category-suggestions" className="flex flex-wrap gap-2 md:mt-4">
            {suggestedCategories.map(category => (
              <JobChip
                key={category.value}
                label={category.label}
                variant="category"
                onClick={() => {
                  onRefine("categories", category.value);
                  onSetSelectedCategories(prev => [...prev, category.value]);
                }}
                isSelected={false}
                count={category.count}
                facetColor={facetsColors?.categoryFacet}
                borderStyle={borderStyle}
              />
            ))}
          </div>
        ) : null}
        {suggestedCities?.length ? (
          <div data-testid="location-suggestions" className="mt-2.5 flex flex-wrap gap-2 md:mt-4">
            {suggestedCities.map(location => (
              <JobChip
                key={location.value}
                label={location.label}
                variant="location"
                onClick={() => {
                  onRefine("cities", location.value);
                  onSetSelectedCities(prev => [...prev, location.value]);
                }}
                isSelected={false}
                count={location.count}
                facetColor={facetsColors?.locationFacet}
                borderStyle={borderStyle}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

export default JobQuerySuggestions;
