import React, { FC, SVGProps } from "react";

const IndustryIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.3302 10H16.6602C15.5602 10 14.6602 10.9 14.6602 12V22H21.3302V12C21.3302 10.9 20.4402 10 19.3302 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.67 14H10C8.9 14 8 14.9 8 16V22H14.67V14Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.29898 8.59211C9.17712 8.59211 9.88898 7.88024 9.88898 7.00211C9.88898 6.12398 9.17712 5.41211 8.29898 5.41211C7.42085 5.41211 6.70898 6.12398 6.70898 7.00211C6.70898 7.88024 7.42085 8.59211 8.29898 8.59211Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.46149V6.53149C3 5.98149 3.45 5.53149 4 5.53149C4.96 5.53149 5.35 4.85149 4.87 4.02149C4.59 3.54149 4.76 2.92149 5.24 2.65149L6.15 2.12149C6.57 1.87149 7.11 2.02149 7.36 2.44149L7.42 2.54149C7.9 3.37149 8.68 3.37149 9.16 2.54149L9.22 2.44149C9.47 2.02149 10.01 1.88149 10.43 2.12149L11.34 2.65149C11.82 2.93149 11.99 3.54149 11.71 4.02149C11.23 4.85149 11.62 5.53149 12.58 5.53149C13.13 5.53149 13.58 5.98149 13.58 6.53149V7.46149C13.58 8.01149 13.13 8.46149 12.58 8.46149C11.62 8.46149 11.23 9.14149 11.71 9.97149C11.99 10.4515 11.82 11.0715 11.34 11.3415L10.43 11.8715C10.01 12.1215 9.47 11.9715 9.22 11.5515L9.16 11.4515C8.68 10.6215 7.9 10.6215 7.42 11.4515L7.36 11.5515C7.11 11.9715 6.57 12.1115 6.15 11.8715L5.24 11.3415C4.76 11.0615 4.59 10.4515 4.87 9.97149C5.35 9.14149 4.96 8.46149 4 8.46149C3.45 8.47149 3 8.02149 3 7.46149Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IndustryIcon;
