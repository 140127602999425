import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Combobox } from "@headlessui/react";
import DownArrowIcon from "../icons/DownArrowIcon";
import UpArrowIcon from "../icons/UpArrowIcon";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import Checkbox from "./Checkbox";
import { useTranslation } from "next-i18next";

interface IInputFilterProps {
  label: string;
  items: RefinementListItem[];
  selectedItems: Array<string>;
  onSetSelectedItems: Dispatch<SetStateAction<string[]>>;
  inputKey: string;
  primaryColorListingSite: string | undefined;
  borderStyle: string;
}

const InputFilter: FC<IInputFilterProps> = ({
  label,
  items,
  selectedItems,
  onSetSelectedItems,
  inputKey,
  primaryColorListingSite,
  borderStyle,
}) => {
  const { t: translate } = useTranslation("listingsite");

  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const filteredInputItems = items?.filter((category: RefinementListItem) =>
    category.value.toLocaleLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <Combobox value={selectedItems} onChange={selectedItems => onSetSelectedItems(selectedItems)} multiple>
      <div className="mb-5">
        <Combobox.Label className="font-medium text-[#666666]">{label}</Combobox.Label>
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white focus:outline-none focus-visible:ring-2">
          <Combobox.Input
            className={`${borderStyle} h-14 w-full text-ellipsis border border-solid border-other-gray pl-5 focus:border-other-gray`}
            onChange={event => setInputValue(event.target.value)}
            readOnly={readOnly}
            placeholder={
              selectedItems.length === 0
                ? "..."
                : selectedItems.length > 2
                ? `Multiple ${inputKey === "job-category-filter" ? "categories" : "locations"}`
                : selectedItems.join(", ")
            }
            data-testid={`${inputKey}`}
            onClick={() => {
              setOpen(true);
              open && setReadOnly(false);
            }}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center pr-5"
            data-testid={`${inputKey}-btn`}
            onClick={() => {
              setOpen(prev => !prev);
            }}
          >
            {open ? (
              <UpArrowIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <DownArrowIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </Combobox.Button>
        </div>
        {open && (
          <Combobox.Options
            className="mt-1.5 max-h-60 overflow-y-auto rounded-md border border-solid border-other-gray px-3.5 pb-2.5"
            static
          >
            {filteredInputItems && filteredInputItems.length > 0 ? (
              filteredInputItems.map((item: RefinementListItem, index: number) => {
                return (
                  <Combobox.Option
                    key={index}
                    value={item.value}
                    className="mb-2 mt-5 flex cursor-pointer list-none items-center gap-2 font-medium text-[#4F4F4F]"
                  >
                    <Checkbox
                      checked={Boolean(selectedItems.find(selected => item.value === selected))}
                      color={primaryColorListingSite}
                      id={`${item.value}-${index}`}
                      borderStyle={borderStyle}
                    />
                    <label className="break-all" htmlFor={`${item.value}-${index}`} onClick={e => e.stopPropagation()}>
                      {item.label}
                    </label>
                  </Combobox.Option>
                );
              })
            ) : (
              <h5 className="mb-3 font-medium">{translate("modalfilters.no_items", "No items found")}</h5>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default InputFilter;
