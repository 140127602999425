import React, { FC, SVGProps } from "react";

const UpArrowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.05994 11.2875L7.94994 6.39747C8.52744 5.81997 9.47244 5.81997 10.0499 6.39747L14.9399 11.2875"
      stroke="#666666"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UpArrowIcon;
