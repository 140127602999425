import React, { FC, SVGProps } from "react";
import ArtIcon from "components/icons/ArtIcon";
import DesignIcon from "components/icons/DesignIcon";
import EducationIcon from "components/icons/EducationIcon";
import HeartIcon from "components/icons/HeartIcon";
import HRIcon from "components/icons/HRIcon";
import IndustryIcon from "components/icons/IndustryIcon";
import LawIcon from "components/icons/LawIcon";
import ManagementIcon from "components/icons/ManagementIcon";
import MarketingIcon from "components/icons/MarketingIcon";
import SalesIcon from "components/icons/SalesIcon";
import TechIcon from "components/icons/TechIcon";
import { IconType } from "pages/[customerSlug]";

interface IDynamicIconProps {
  icon: IconType;
}

const DynamicIcon: FC<IDynamicIconProps> = ({ icon }) => {
  type iconsObject = {
    [key: string]: FC<SVGProps<SVGSVGElement>>;
  };

  const icons: iconsObject = {
    education: EducationIcon,
    sales: SalesIcon,
    art: ArtIcon,
    health: HeartIcon,
    humanresources: HRIcon,
    management: ManagementIcon,
    law: LawIcon,
    tech: TechIcon,
    industry: IndustryIcon,
    marketing: MarketingIcon,
    design: DesignIcon,
  };

  const Component = icons[icon || ""];

  return Component ? <Component /> : null;
};

export default DynamicIcon;
