import { Dialog, Transition } from "@headlessui/react";
import { RefinableAttribute } from "hooks/useRefinements";
import React, { Dispatch, FC, Fragment, SetStateAction, useState } from "react";
import InputFilter from "./InputFilter";
import FilterIcon from "../icons/FilterIcon";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import { blackContrast, whiteContrast } from "utils/colorsHelpers";
import Checkbox from "./Checkbox";
import { ISelectedJobTime } from "./JobSearch";
import { IDefaultCoords } from "./JobSearchProvider";
import { useTranslation } from "next-i18next";
interface IModalFiltersProps {
  onRefine: (attribute: RefinableAttribute, value: string) => void;
  categoryItems?: RefinementListItem[];
  cityItems: RefinementListItem[];
  isPartTimeItems: RefinementListItem[];
  query?: string | undefined;
  refinementList?: { [attribute: string]: string[] } | undefined;
  onClearRefinements: () => void;
  onRefineJobType: (selectedJobType: string) => void;
  primaryColorListingSite: string | undefined;
  onClearJobType: () => void;
  aroundLatLng: string | undefined;
  onSetSelectedCategories: Dispatch<SetStateAction<string[]>>;
  selectedCategories: Array<string>;
  selectedCities: Array<string>;
  onSetSelectedCities: Dispatch<SetStateAction<string[]>>;
  selectedJobTypes: ISelectedJobTime;
  onSetSelectedJobTypes: Dispatch<SetStateAction<ISelectedJobTime>>;
  onSetCoords: Dispatch<SetStateAction<IDefaultCoords>>;
  borderStyle: string;
}

const ModalFilters: FC<IModalFiltersProps> = ({
  categoryItems,
  cityItems,
  onRefine,
  query,
  refinementList,
  onClearRefinements,
  onRefineJobType,
  primaryColorListingSite,
  aroundLatLng,
  onClearJobType,
  selectedCategories,
  onSetSelectedCategories,
  selectedCities,
  onSetSelectedCities,
  selectedJobTypes,
  onSetSelectedJobTypes,
  onSetCoords,
  borderStyle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { t: translate } = useTranslation("listingsite");

  const checkIfItemIsSelected = (item: string, selectedCollection: string[]) => {
    return Boolean(selectedCollection.find(selected => item === selected));
  };

  const handleApplyFilters = () => {
    const jobTypeToRefine = Object.keys(selectedJobTypes).find(key => {
      return selectedJobTypes[key as keyof ISelectedJobTime] === true;
    });

    categoryItems?.forEach(
      item =>
        ((checkIfItemIsSelected(item.label, selectedCategories) && !item.isRefined) ||
          (!checkIfItemIsSelected(item.label, selectedCategories) && item.isRefined)) &&
        onRefine("categories", item.value)
    );

    cityItems?.forEach(
      item =>
        ((checkIfItemIsSelected(item.label, selectedCities) && !item.isRefined) ||
          (!checkIfItemIsSelected(item.label, selectedCities) && item.isRefined)) &&
        onRefine("cities", item.value)
    );

    jobTypeToRefine && onRefineJobType(jobTypeToRefine);
    !jobTypeToRefine && onClearJobType();
  };

  const handleClearAllFilters = () => {
    onClearRefinements();
    onSetSelectedCategories([]);
    onSetSelectedCities([]);
    onSetSelectedJobTypes({
      isPartTime: false,
      isFullTime: false,
    });
    onSetCoords(prev => ({ ...prev, isApplied: false }));
    setIsModalOpen(false);
  };

  if (!query && !refinementList && !aroundLatLng) {
    return null;
  }

  return (
    <>
      <div
        className={`${borderStyle} flex h-20 w-24 cursor-pointer bg-filter-button-color text-center hover:bg-filter-button-color-hover`}
        onClick={() => setIsModalOpen(true)}
        data-testid="modal-filters"
      >
        {(refinementList || aroundLatLng) && (
          <span className={`absolute h-1.5 w-1.5 rounded-full bg-secondary-listing-site`}></span>
        )}
        <FilterIcon className={`m-auto h-7 w-7 text-secondary-listing-site`} />
      </div>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={() => setIsModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 mt-0 inline-block overflow-y-auto">
            <div
              className={`${
                refinementList || query ? "mt-[160px] md:mt-[140px]" : "mt-44 md:mt-32"
              }  flex w-full items-center justify-center p-4 `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-body-container md:p-5">
                  <Dialog.Panel className="custom-border max-h-full transform overflow-hidden bg-white p-5 text-left align-middle shadow-xl transition-all">
                    <InputFilter
                      label={translate("modalfilters.job_category_text", "Job category")}
                      items={categoryItems || []}
                      inputKey="job-category-filter"
                      primaryColorListingSite={primaryColorListingSite}
                      selectedItems={selectedCategories}
                      onSetSelectedItems={onSetSelectedCategories}
                      borderStyle={borderStyle}
                    />

                    <InputFilter
                      label={translate("modalfilters.location_text", "Location")}
                      items={cityItems || []}
                      selectedItems={selectedCities}
                      onSetSelectedItems={onSetSelectedCities}
                      inputKey="location-filter"
                      primaryColorListingSite={primaryColorListingSite}
                      borderStyle={borderStyle}
                    />

                    <div className="mb-10">
                      <span className="mb-5 font-medium text-medium-grey">
                        {translate("modalfilters.job_type_text")}
                      </span>
                      <div className="mb-4 mt-2 flex gap-2">
                        <Checkbox
                          checked={Boolean(selectedJobTypes.isFullTime)}
                          onClick={() =>
                            onSetSelectedJobTypes(prev => ({
                              isPartTime: false,
                              isFullTime: !prev.isFullTime,
                            }))
                          }
                          color={primaryColorListingSite}
                          id={`job-type-full-time`}
                          borderStyle={borderStyle}
                        />
                        <label htmlFor="job-type-full-time">{translate("jobcard.suggestions.full_time_text")}</label>
                      </div>
                      <div className="flex gap-2">
                        <Checkbox
                          checked={Boolean(selectedJobTypes.isPartTime)}
                          onClick={() =>
                            onSetSelectedJobTypes(prev => ({
                              isPartTime: !prev.isPartTime,
                              isFullTime: false,
                            }))
                          }
                          color={primaryColorListingSite}
                          id={`job-type-part-time`}
                          borderStyle={borderStyle}
                        />
                        <label htmlFor="job-type-part-time">{translate("jobcard.suggestions.part_time_text")}</label>
                      </div>
                    </div>

                    <div className="flex justify-center gap-2.5 md:justify-end">
                      <button
                        data-testid="clear-all-button"
                        className={`${borderStyle} hover:bg- whitespace-nowrap rounded-md bg-clear-grey px-14 py-4 font-bold leading-4 text-light-grey hover:bg-clear-all-grey`}
                        onClick={() => handleClearAllFilters()}
                      >
                        {translate("modalfilters.clear_all_text", "Clear All")}
                      </button>
                      <button
                        data-testid="apply-filters-btn"
                        className={`${borderStyle} whitespace-nowrap rounded-md bg-primary-listing-site hover:bg-primary-listing-site-hover ${
                          blackContrast(primaryColorListingSite) > whiteContrast(primaryColorListingSite)
                            ? "text-black"
                            : "text-white"
                        } px-9 py-4 font-bold leading-4 text-white `}
                        onClick={() => {
                          handleApplyFilters();
                          setIsModalOpen(false);
                        }}
                      >
                        {translate("modalfilters.apply_filters_text", " Apply Filters")}
                      </button>
                    </div>
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalFilters;
