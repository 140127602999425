import React, { FC, SVGProps } from "react";

const ArtIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_16761_2575)">
      <path
        d="M10.9702 2H8.97021C3.97021 2 1.97021 4 1.97021 9V15C1.97021 20 3.97021 22 8.97021 22H14.9702C19.9702 22 21.9702 20 21.9702 15V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8799 3.55998C20.6499 6.62998 17.5599 10.81 14.9799 12.88L13.3999 14.14C13.1999 14.29 12.9999 14.41 12.7699 14.5C12.7699 14.35 12.7599 14.2 12.7399 14.04C12.6499 13.37 12.3499 12.74 11.8099 12.21C11.2599 11.66 10.5999 11.35 9.91994 11.26C9.75994 11.25 9.59994 11.24 9.43994 11.25C9.52994 11 9.65994 10.77 9.82994 10.58L11.0899 8.99998C13.1599 6.41998 17.3499 3.30998 20.4099 2.07998C20.8799 1.89998 21.3399 2.03998 21.6299 2.32998C21.9299 2.62998 22.0699 3.08998 21.8799 3.55998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7801 14.49C12.7801 15.37 12.4401 16.21 11.8101 16.85C11.3201 17.34 10.6601 17.68 9.87009 17.78L7.90009 17.99C6.83009 18.11 5.91009 17.2 6.03009 16.11L6.24009 14.14C6.43009 12.39 7.89009 11.27 9.45009 11.24C9.61009 11.23 9.77009 11.24 9.93009 11.25C10.6101 11.34 11.2701 11.65 11.8201 12.2C12.3601 12.74 12.6601 13.36 12.7501 14.03C12.7701 14.19 12.7801 14.35 12.7801 14.49Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8198 11.9799C15.8198 9.88994 14.1298 8.18994 12.0298 8.18994"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16761_2575">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArtIcon;
