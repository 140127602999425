import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { IJobQuerySuggestionsProps } from "./JobQuerySuggestions";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import JobChip from "./JobChip";
import { ISelectedJobTime } from "./JobSearch";
import { IDefaultCoords } from "./JobSearchProvider";
import { useTranslation } from "next-i18next";

export interface IActiveFacetsProps extends IJobQuerySuggestionsProps {
  isPartTimeItems: RefinementListItem[];
  isRemoteItems: RefinementListItem[];
  onSetSelectedCategories: Dispatch<SetStateAction<string[]>>;
  onSetSelectedCities: Dispatch<SetStateAction<string[]>>;
  onSetSelectedJobTypes: Dispatch<SetStateAction<ISelectedJobTime>>;
  nearMeApplied: boolean;
  onSetCoords: Dispatch<SetStateAction<IDefaultCoords>>;
}

const ActiveFacets: FC<IActiveFacetsProps> = ({
  categoryItems,
  cityItems,
  isPartTimeItems,
  isRemoteItems,
  onRefine,
  onSetSelectedCategories,
  onSetSelectedCities,
  onSetSelectedJobTypes,
  nearMeApplied,
  onSetCoords,
  facetsColors,
  borderStyle,
}) => {
  const { t: translate } = useTranslation("listingsite");

  const refinedCategoryItems = useMemo(() => {
    return categoryItems.filter(item => item.isRefined);
  }, [categoryItems]);
  const refinedCityItems = useMemo(() => {
    return cityItems.filter(item => item.isRefined);
  }, [cityItems]);
  const refinedIsPartTimeItems = useMemo(() => {
    return isPartTimeItems.filter(item => item.isRefined);
  }, [isPartTimeItems]);
  const refinedIsRemoteItems = useMemo(() => {
    return isRemoteItems.filter(item => item.isRefined);
  }, [isRemoteItems]);

  return (
    <div className="mt-4 flex flex-wrap gap-2 md:mt-4">
      {nearMeApplied && (
        <JobChip
          key="third-card"
          variant="location"
          label={translate("suggestions.cards.near_me_text", "Near me")}
          isSelected
          onClick={() =>
            onSetCoords(prev => ({
              ...prev,
              isApplied: false,
            }))
          }
          facetColor={facetsColors?.locationFacet}
          borderStyle={borderStyle}
        />
      )}
      {refinedCategoryItems.map(category => (
        <JobChip
          key={category.value}
          label={category.label}
          variant="category"
          onClick={() => {
            onSetSelectedCategories(prev => prev.filter(item => item !== category.value));
            onRefine("categories", category.value);
          }}
          isSelected
          count={category.count}
          facetColor={facetsColors?.categoryFacet}
          borderStyle={borderStyle}
        />
      ))}
      {refinedCityItems.map(city => (
        <JobChip
          key={city.value}
          label={city.label}
          variant="location"
          onClick={() => {
            onSetSelectedCities(prev => prev.filter(item => item !== city.value));
            onRefine("cities", city.value);
          }}
          isSelected
          count={city.count}
          facetColor={facetsColors?.locationFacet}
          borderStyle={borderStyle}
        />
      ))}
      {refinedIsPartTimeItems.map(isPartTime => (
        <JobChip
          key={isPartTime.value}
          label={
            isPartTime.value === "true"
              ? translate("jobcard.suggestions.part_time_text")
              : translate("jobcard.suggestions.full_time_text")
          }
          variant="other"
          onClick={() => {
            onRefine("isPartTime", isPartTime.value);
            isPartTime.value === "true"
              ? onSetSelectedJobTypes(prev => ({
                  isPartTime: !prev.isPartTime,
                  isFullTime: false,
                }))
              : onSetSelectedJobTypes(prev => ({
                  isPartTime: false,
                  isFullTime: !prev.isFullTime,
                }));
          }}
          isSelected
          count={isPartTime.count}
          facetColor={facetsColors?.otherFacet}
          borderStyle={borderStyle}
        />
      ))}
      {refinedIsRemoteItems.map(isRemote => (
        <JobChip
          key={isRemote.value}
          label={translate("jobchips.remote_text", "Remote")}
          variant="other"
          onClick={() => onRefine("isRemote", isRemote.value)}
          isSelected
          count={isRemote.count}
          facetColor={facetsColors?.otherFacet}
          borderStyle={borderStyle}
        />
      ))}
    </div>
  );
};

export default ActiveFacets;
