import algoliasearch from "algoliasearch/lite";
export type AlgoliaIndex =
  | "instant_search"
  | "instant_search_location_suggestions"
  | "instant_search_category_suggestions";

export const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || ""
);
