import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import JobSearchField from "./JobSearchField";
import JobHits from "./JobHits";
import JobQuerySuggestions from "./JobQuerySuggestions";
import GoHome from "./GoHome";
import Suggestions from "./Suggestions";
import { IDefaultCoords } from "./JobSearchProvider";
import useRefinements from "hooks/useRefinements";
import ActiveFacets from "./ActiveFacets";
import ModalFilters from "./ModalFilters";
import ListingSiteTitle from "./ListingSiteTitle";
import { useClearRefinements, useInstantSearch } from "react-instantsearch";
import { IListingSiteData } from "pages/[customerSlug]";
import AboutUsCard from "./AboutUsCard";
import Logo from "../Logo";
import RecentVisit from "./RecentVisit";
import { useIsInIframe } from "hooks/useIsInIframe";
import algoliaAnalytics from "search-insights";
import { useBrowserStorage } from "hooks/useBrowserStorage";
import { v4 as uuid } from "uuid";

interface IJobSearchProps {
  coords: IDefaultCoords;
  onSetCoords: Dispatch<SetStateAction<IDefaultCoords>>;
  listingSiteData: IListingSiteData;
  customerSlug: string;
}

export interface ISelectedJobTime {
  isFullTime: boolean;
  isPartTime: boolean;
}

interface DefaultRecentVisit {
  jobs: string[];
}
export const DEFAULT_RECENT_VISIT: DefaultRecentVisit = {
  jobs: [],
};

export interface IAlgoliaId {
  value: string;
}

const DEFAULT_ALGOLIA_ID: IAlgoliaId = {
  value: "",
};

const JobSearch: FC<IJobSearchProps> = ({ coords, onSetCoords, listingSiteData, customerSlug }) => {
  const refinements = useRefinements();

  const isIframe = useIsInIframe();
  const [algoliaId, setAlgoliaId] = useBrowserStorage<IAlgoliaId>("algoliaId", "sessionStorage", DEFAULT_ALGOLIA_ID);

  // We need to set a token by ourselves to be able to use it for conversion.
  useEffect(() => {
    if (!algoliaId.value) {
      setAlgoliaId({ value: uuid() });
      algoliaAnalytics("setUserToken", algoliaId.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { indexUiState } = useInstantSearch();
  const { query, refinementList, configure } = indexUiState;
  const { refine: clearRefinements } = useClearRefinements();
  const { refine: clearJobType } = useClearRefinements({ includedAttributes: ["isPartTime"] });

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<ISelectedJobTime>({
    isFullTime: false,
    isPartTime: false,
  });

  const {
    showLogo,
    showTitle,
    showBackground,
    showAboutUs,
    firstCardColor,
    secondCardColor,
    thirdCardColor,
    primaryColorListingSite,
    whiteText,
    showJobsFirstPage,
    logoImage,
    shape,
    isLogoImageTransparent,
  } = listingSiteData;

  const isFirstPage = !query && !refinementList && !configure?.aroundLatLng;
  return (
    <div
      className={`${isFirstPage && !isIframe ? "min-h-screen" : ""} md:max-w-body-container ${
        showBackground ? "bg-body" : ""
      } px-5 pb-5 md:px-0`}
    >
      <div className={`flex`}>
        <GoHome
          onClick={() =>
            onSetCoords(prev => ({
              ...prev,
              isApplied: false,
            }))
          }
          whiteText={whiteText}
        />
        {showLogo && (
          <div className="ml-auto">
            <div className={`min-w-12 max-w-12 relative mt-5 aspect-square h-12 w-12 bg-transparent`}>
              <Logo logoImage={logoImage} shape={shape} isLogoImageTransparent={isLogoImageTransparent} />
            </div>
          </div>
        )}
      </div>
      {showTitle && <ListingSiteTitle whiteText={whiteText} />}
      <div className={`${(!showLogo || !showTitle) && "mt-8"} flex gap-2.5`}>
        <JobSearchField borderStyle={listingSiteData.roundedBorders} />
        <ModalFilters
          {...refinements}
          onSetSelectedCategories={setSelectedCategories}
          selectedCategories={selectedCategories}
          selectedCities={selectedCities}
          onSetSelectedCities={setSelectedCities}
          selectedJobTypes={selectedJobTypes}
          onSetSelectedJobTypes={setSelectedJobTypes}
          query={query}
          refinementList={refinementList}
          onClearRefinements={clearRefinements}
          primaryColorListingSite={primaryColorListingSite}
          onClearJobType={clearJobType}
          aroundLatLng={configure?.aroundLatLng}
          onSetCoords={onSetCoords}
          borderStyle={listingSiteData.roundedBorders}
        />
      </div>
      <ActiveFacets
        {...refinements}
        onSetSelectedCategories={setSelectedCategories}
        onSetSelectedCities={setSelectedCities}
        onSetSelectedJobTypes={setSelectedJobTypes}
        nearMeApplied={coords.isApplied}
        onSetCoords={onSetCoords}
        facetsColors={listingSiteData.facetsColors}
        borderStyle={listingSiteData.roundedBorders}
      />
      <Suggestions
        onSetCoords={onSetCoords}
        coords={coords}
        query={query}
        refinementList={refinementList}
        aroundLatLng={configure?.aroundLatLng}
        listingSiteData={listingSiteData}
        customerSlug={customerSlug}
        onSetSelectedJobTypes={setSelectedJobTypes}
        onSetSelectedCategories={setSelectedCategories}
        firstCardColor={firstCardColor}
        secondCardColor={secondCardColor}
        thirdCardColor={thirdCardColor}
        whiteText={whiteText}
        borderStyle={listingSiteData.roundedBorders}
        {...refinements}
      />
      <JobQuerySuggestions
        {...refinements}
        query={query}
        refinementList={refinementList}
        aroundLatLng={configure?.aroundLatLng}
        onSetSelectedCategories={setSelectedCategories}
        onSetSelectedCities={setSelectedCities}
        whiteText={whiteText}
        facetsColors={listingSiteData.facetsColors}
        borderStyle={listingSiteData.roundedBorders}
      />
      {showAboutUs && isFirstPage && (
        <div className="max-w-44 mt-4 md:mt-8">
          <AboutUsCard
            title={listingSiteData.title || ""}
            description={listingSiteData?.shortDescription || ""}
            customerSlug={customerSlug}
            query={query}
            refinementList={refinementList}
            aroundLatLng={configure?.aroundLatLng}
            backgroundColor={listingSiteData.aboutUs.backgroundColor}
            borderStyle={listingSiteData.roundedBorders}
          />
        </div>
      )}
      <div className="max-w-44 mt-4">
        {!isFirstPage && (
          <JobHits
            whiteText={whiteText}
            showBackground={Boolean(showBackground)}
            borderStyle={listingSiteData.roundedBorders}
          />
        )}
      </div>
      {isFirstPage && showJobsFirstPage && (
        <RecentVisit
          whiteText={whiteText}
          customerSlug={customerSlug}
          borderStyle={listingSiteData.roundedBorders}
          showBackground={Boolean(showBackground)}
        />
      )}
    </div>
  );
};

export default JobSearch;
