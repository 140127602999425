import React from "react";
import type { GetServerSideProps, NextPage } from "next";
import { renderToString } from "react-dom/server";
import { useFeatureFlag } from "configcat-react";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { InstantSearchServerState, getServerState } from "react-instantsearch";
import { getParamAsString } from "utils/pathHelpers";
import JobSearchProvider from "components/listingsite/JobSearchProvider";
import { getCustomer } from "api/customers";
import { darkenColor, filterIconColor, getFilterButtonBackgroundColor } from "utils/colorsHelpers";
import NotFound from "components/NotFound";
import CookieSettingsButton from "components/CookieSettingsButton";
import LegalmonsterScript, { localeMap } from "lib/legalmonster";
import { getFontNameFromUrl } from "utils/fontHelpers";
import Script from "next/script";
import { useIsInIframe } from "hooks/useIsInIframe";
import { NextSeo } from "next-seo";
import { getPlausibleDomains } from "lib/PlausibleTracking";
import { ICustomer } from "api/customers";

export type IconType =
  | "education"
  | "sales"
  | "art"
  | "health"
  | "humanresources"
  | "management"
  | "law"
  | "tech"
  | "industry"
  | "marketing"
  | "design";

export interface IListingSiteData {
  fallbackCategory: string;
  fallbackIcon: IconType;
  primaryColorListingSite: string;
  secondaryColorListingSite: string;
  title: string;
  shortDescription: string;
  logoImage: string;
  showJobsFirstPage: boolean;
  showLogo?: boolean;
  showTitle?: boolean;
  showBackground?: boolean;
  showAboutUs?: boolean;
  firstCardColor: string;
  secondCardColor: string;
  thirdCardColor: string;
  fontFamily?: string;
  fontSize?: string;
  shape: "circle" | "square" | "none";
  isLogoImageTransparent?: boolean;
  whiteText: boolean;
  customerName: string;
  facetsColors: {
    locationFacet: string;
    categoryFacet: string;
    otherFacet: string;
  };
  roundedBorders: string;
  aboutUs: {
    title: string;
    shortDescription: string;
    longDescription: string;
    backgroundColor: string;
  };
}
export interface IListingSiteProps {
  serverState?: InstantSearchServerState;
  url?: string;
  customerSlug: string;
  listingSiteData: IListingSiteData;
  customerExists: boolean;
  enableListingSite: boolean;
  legalWidgetId: string;
  legalLocale: string;
  customer: ICustomer | null;
  bypassCookieConsent: boolean;
}

const ListingSite: NextPage<IListingSiteProps> = props => {
  const {
    listingSiteData,
    legalLocale,
    legalWidgetId,
    customerExists,
    enableListingSite,
    customerSlug,
    customer,
    bypassCookieConsent,
  } = props;

  const {
    primaryColorListingSite,
    secondaryColorListingSite,
    firstCardColor,
    secondCardColor,
    thirdCardColor,
    showBackground,
    facetsColors,
    aboutUs,
  } = listingSiteData;

  const filterButtonColor = getFilterButtonBackgroundColor(primaryColorListingSite);

  const { value: isListingSiteEnabled } = useFeatureFlag("LISTING_SITE", false);
  const isIframe = useIsInIframe();

  if (!isListingSiteEnabled) return null;

  if (!customerExists || !enableListingSite) {
    return <NotFound />;
  }

  const fontName = getFontNameFromUrl(listingSiteData?.fontFamily);
  const shouldSetFontSettings = fontName || listingSiteData?.fontSize !== "16px";

  const seoTitle = `Start Your Next Adventure with ${listingSiteData.customerName} - Apply for a Career Today `;
  const seoDescription = `Looking for a new adventure in your career? Join the ${listingSiteData.customerName} team and explore new opportunities for growth and learning. We're seeking talented and driven individuals who are ready to take on new challenges. Apply now and let's start this adventure together with ${listingSiteData.customerName}.`;
  const url = `${process.env.NEXT_PUBLIC_ENGAGE_BASE_URI}/${customerSlug}`;
  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.0.0/themes/algolia-min.css" />
        <link rel="shortcut icon" href="/favicon-32x32.png" />
        {/* <script
          defer
          data-domain={getPlausibleDomains(customer)}
          src="/prx/js/script.manual.js"
          data-api="/prx/api/event"
        /> */}

        <script
          defer
          event-customerSlug={customerSlug}
          data-domain={getPlausibleDomains(customer)}
          src="https://plausible.io/js/script.pageview-props.js"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              :root { --color-primary-listing-site: ${primaryColorListingSite}}
              :root { --color-primary-listing-site-hover: ${darkenColor(primaryColorListingSite, 4)}}
              :root { --color-secondary-listing-site: ${secondaryColorListingSite}}
              :root { --color-filter-button-color: ${filterButtonColor}}
              :root { --color-filter-button-color-hover: ${darkenColor(filterButtonColor, 4)}}
              :root { --color-first-card: ${firstCardColor}}
              :root { --color-second-card: ${secondCardColor}}
              :root { --color-third-card: ${thirdCardColor}}
              :root { --color-location-facet: ${facetsColors?.locationFacet}}
              :root { --color-category-facet: ${facetsColors?.categoryFacet}}
              :root { --color-other-facet: ${facetsColors?.otherFacet}}
              :root { --color-about-us-color: ${aboutUs?.backgroundColor}}
              :root { --color-about-us-hover-color: ${darkenColor(aboutUs?.backgroundColor, 4)}}
              :root { --color-about-us-active-color: ${darkenColor(aboutUs?.backgroundColor, 2)}}
              :root { --color-first-card-dark: ${darkenColor(firstCardColor, 7)}}
              :root { --color-second-card-dark: ${darkenColor(secondCardColor, 7)}}
              :root { --color-third-card-dark: ${darkenColor(thirdCardColor, 7)}}
              :root { --color-first-card-hover: ${darkenColor(firstCardColor, 4)}}
              :root { --color-second-card-hover: ${darkenColor(secondCardColor, 4)}}
              :root { --color-third-card-hover: ${darkenColor(thirdCardColor, 4)}}
              html, body {
                background-color: ${showBackground ? "#f6f5f8" : "transparent"} !important; 
                border: ${!showBackground && "none"}
              }
            `,
          }}
        />
        {shouldSetFontSettings && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
                    @font-face {
                      font-family: '${fontName || "Montserrat"}';
                      font-weight: 400;
                      font-size: ${listingSiteData?.fontSize || "16px"};
                      src: url(${listingSiteData?.fontFamily});
                    }
                    html, body {
                      font-family: '${fontName || "Montserrat"}', sans-serif !important;
                      font-size: ${listingSiteData?.fontSize || "16px"} !important;
                    }
            `,
            }}
          />
        )}
      </Head>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          url,
          title: seoTitle,
          description: seoDescription,
          type: "website",
        }}
      />
      <main className="mx-auto max-w-body-container text-base">
        <JobSearchProvider {...props} />
      </main>
      {/* This script tag is just to test if hotjar works. */}
      <Script
        id="hotjar"
        type="text/plain"
        data-cookieconsent="analytics"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3422020,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
         `,
        }}
      />
      {!isIframe && !bypassCookieConsent && (
        <>
          <CookieSettingsButton />
          <LegalmonsterScript legalLocale={legalLocale} legalWidgetId={legalWidgetId} />
        </>
      )}
    </>
  );
};

export default ListingSite;

export const getServerSideProps: GetServerSideProps = async ({ locale, req, params }) => {
  const customerSlug = getParamAsString(params?.customerSlug);
  const customer = await getCustomer(customerSlug);
  const listingSiteData = {
    fallbackIcon: customer?.fallbackIcon || "education",
    fallbackCategory: customer?.fallbackCategory || "",
    primaryColorListingSite: customer?.primaryColor || "",
    secondaryColorListingSite: filterIconColor(customer?.primaryColor) || "",
    title: customer?.aboutUs.title || "",
    shortDescription: customer?.aboutUs.shortDescription || "",
    logoImage: customer?.logoImage || "",
    showJobsFirstPage: customer?.embedOptions.showJobsFirstPage || false,
    showLogo: customer?.embedOptions.showLogo || false,
    showAboutUs: customer?.embedOptions.showAboutUs || false,
    showTitle: customer?.embedOptions.showTitle || false,
    showBackground: customer?.embedOptions.showBackground || false,
    firstCardColor: customer?.embedOptions.firstCardColor || "#C1E4F4",
    secondCardColor: customer?.embedOptions.secondCardColor || "#E0D3FC",
    thirdCardColor: customer?.embedOptions.thirdCardColor || "#D1EFC2",
    fontSize: customer?.fontSize || "",
    fontFamily: customer?.fontFamily || "",
    isLogoImageTransparent: customer?.isLogoImageTransparent || false,
    shape: customer?.shape || "square",
    whiteText: customer?.embedOptions.whiteText || false,
    customerName: customer?.name || "",
    facetsColors: {
      locationFacet: customer?.facetsColors?.locationFacet || "#EEE2FD",
      categoryFacet: customer?.facetsColors?.categoryFacet || "#FECD83",
      otherFacet: customer?.facetsColors?.otherFacet || "#F2F0F4",
    },
    roundedBorders: customer?.roundedBorders || "rounded-base",
    aboutUs: {
      title: customer?.aboutUs.title || "",
      shortDescription: customer?.aboutUs.shortDescription || "",
      longDescription: customer?.aboutUs.longDescription || "",
      backgroundColor: customer?.aboutUs.backgroundColor || "#EFE2C2",
    },
  };

  const listingSiteLanguage = customer?.listingSiteLanguage;

  const language = locale || "en";
  const legalWidgetId = language === "sv" ? "RTCknRuK4s4VeotZqexS8s3W" : "b1TCQihYXcHpMHHBArUqVf2f";
  const legalLocale = localeMap[language] || "en-us";
  const protocol = req.headers.referer?.split("://")[0] || "https";
  const url = `${protocol}://${req.headers.host}${req.url}`;

  // Determine if the originating country of the request is US
  const geolocationHeader = req.headers["X-Vercel-IP-Country"] || "SV";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const originatingCountryIsUS = geolocationHeader?.toString().toLowerCase() === "us";

  // ATTENTION: This is a temporary solution to bypass cookie consent for a specific US customer as a test
  const bypassCookieConsent = customer?.slug === "service-professionals" || customerSlug === "bilal-test";

  const serverState = await getServerState(
    <JobSearchProvider
      url={url}
      customerSlug={customerSlug}
      listingSiteData={listingSiteData}
      customerExists={Boolean(customer)}
      enableListingSite={Boolean(customer?.enableListingSite)}
      legalLocale={legalLocale}
      legalWidgetId={legalWidgetId}
      customer={customer}
      bypassCookieConsent={bypassCookieConsent}
    />,
    {
      renderToString,
    }
  );

  return {
    props: {
      serverState,
      url,
      customerSlug,
      ...(await serverSideTranslations(listingSiteLanguage || "en", ["listingsite", "common"])),
      listingSiteData,
      customerExists: Boolean(customer),
      enableListingSite: Boolean(customer?.enableListingSite),
      legalWidgetId,
      legalLocale,
      customer,
      bypassCookieConsent,
    },
  };
};
