import React, { FC } from "react";
import { blackContrast, whiteContrast } from "utils/colorsHelpers";

interface IJobChipProps {
  variant: "category" | "location" | "other";
  label: string;
  count?: number;
  onClick: () => void;
  isSelected?: boolean;
  facetColor: string;
  borderStyle: string;
}

const JobChip: FC<IJobChipProps> = ({ variant, label, count, onClick, isSelected, facetColor, borderStyle }) => {
  const bgClassName =
    variant === "category" ? "bg-category-facet" : variant === "location" ? "bg-location-facet" : "bg-other-facet";

  const textColor = blackContrast(facetColor) > whiteContrast(facetColor) ? "text-dark-grey" : "text-white";

  return (
    <div
      data-testid={`job-chip-${variant}`}
      className={`${borderStyle} rounded-custom-border max-w-fit text-xs ${bgClassName} ${textColor} px-2 py-1 hover:cursor-pointer hover:opacity-90`}
      onClick={onClick}
    >
      {label}
      {count ? ` (${count})` : ""}
      {isSelected && (
        <button data-testid={`job-chip-${variant}-remove-btn`} className="ml-2 rotate-90">
          x
        </button>
      )}
    </div>
  );
};

export default JobChip;
