import { useRefinementList } from "react-instantsearch";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";

export type RefinableAttribute = "cities" | "categories" | "isRemote" | "isPartTime";

interface IUseRefinements {
  onRefine: (attribute: RefinableAttribute, value: string) => void;
  cityItems: RefinementListItem[];
  categoryItems: RefinementListItem[];
  isRemoteItems: RefinementListItem[];
  isPartTimeItems: RefinementListItem[];
  onRefineJobType: (selectedJobType: string) => void;
}

const useRefinements = (): IUseRefinements => {
  const { refine: handleRefineCities, items: cityItems } = useRefinementList({ attribute: "cities" });
  const { refine: handleRefineCategories, items: categoryItems } = useRefinementList({ attribute: "categories" });
  const { refine: handleRefineIsRemote, items: isRemoteItems } = useRefinementList({ attribute: "isRemote" });
  const { refine: handleRefineIsPartTime, items: isPartTimeItems } = useRefinementList({ attribute: "isPartTime" });

  const handleRefine = (attribute: RefinableAttribute, value: string) => {
    switch (attribute) {
      case "cities":
        handleRefineCities(value);
        break;

      case "categories":
        handleRefineCategories(value);
        break;

      case "isPartTime":
        handleRefineIsPartTime(value);
        break;

      case "isRemote":
        handleRefineIsRemote(value);
        break;
    }
  };

  const handleRefineJobType = (selectedJobTypes: string) => {
    if (
      selectedJobTypes === "isPartTime" &&
      Boolean(isPartTimeItems.find(item => item.isRefined && item.value === "false"))
    ) {
      handleRefine("isPartTime", "false");
      handleRefine("isPartTime", "true");
    } else if (
      selectedJobTypes === "isFullTime" &&
      Boolean(isPartTimeItems.find(item => item.isRefined && item.value === "true"))
    ) {
      handleRefine("isPartTime", "true");
      handleRefine("isPartTime", "false");
    } else if (selectedJobTypes === "isFullTime") {
      handleRefine("isPartTime", "false");
    } else if (selectedJobTypes === "isPartTime") {
      handleRefine("isPartTime", "true");
    }
  };

  return {
    onRefine: handleRefine,
    onRefineJobType: handleRefineJobType,
    cityItems,
    categoryItems,
    isRemoteItems,
    isPartTimeItems,
  };
};

export default useRefinements;
