import { IListingSiteData } from "pages/[customerSlug]";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useClearRefinements } from "react-instantsearch";
import DynamicIcon from "./DynamicIcon";
import ClockIcon from "../icons/ClockIcon";
import HouseIcon from "../icons/HouseIcon";
import LocationIcon from "../icons/LocationIcon";
import { IDefaultCoords } from "./JobSearchProvider";
import { ISuggestionCardProps } from "./SuggestionCard";
import SuggestionCardLayout from "./SuggestionCardsLayout";
import { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import { RefinableAttribute } from "hooks/useRefinements";
import { algoliaClient } from "lib/algoliasearch";
import { ISelectedJobTime } from "./JobSearch";
import RefreshIcon from "components/icons/RefreshIcon";
import { useTranslation } from "next-i18next";

interface ISuggestionsProps {
  onSetCoords: Dispatch<SetStateAction<IDefaultCoords>>;
  coords: IDefaultCoords;
  query?: string | undefined;
  refinementList?: { [attribute: string]: string[] } | undefined;
  aroundLatLng: string | undefined;
  listingSiteData: IListingSiteData;
  isRemoteItems: RefinementListItem[];
  categoryItems: RefinementListItem[];
  isPartTimeItems: RefinementListItem[];
  onRefine: (attribute: RefinableAttribute, value: string) => void;
  customerSlug: string;
  onSetSelectedCategories: Dispatch<SetStateAction<string[]>>;
  onSetSelectedJobTypes: Dispatch<SetStateAction<ISelectedJobTime>>;
  firstCardColor: string;
  secondCardColor: string;
  thirdCardColor: string;
  whiteText: boolean;
  borderStyle: string;
}

const Suggestions: FC<ISuggestionsProps> = ({
  onSetCoords,
  coords,
  query,
  refinementList,
  aroundLatLng,
  listingSiteData,
  categoryItems,
  isPartTimeItems,
  isRemoteItems,
  onRefine,
  customerSlug,
  onSetSelectedJobTypes,
  onSetSelectedCategories,
  firstCardColor,
  secondCardColor,
  thirdCardColor,
  whiteText,
  borderStyle,
}) => {
  const { latitude, longitude } = coords;

  const { t: translate } = useTranslation("listingsite");

  const { refine: clearRefinements } = useClearRefinements();

  const showSuggestions = query || refinementList || aroundLatLng;

  const [loadingNearMe, setLoadingNearMe] = useState<boolean>(false);

  const hasNoRemoteJobs = !isRemoteItems.some((item: RefinementListItem) => item.value === "true");
  const firstCardText = hasNoRemoteJobs
    ? listingSiteData?.fallbackCategory
    : translate("suggestions.cards.remote_jobs_text", "Remote jobs");
  const fallbackCategoryCount = categoryItems.find(
    category => category.value === listingSiteData?.fallbackCategory
  )?.count;
  const remoteJobsCount = isRemoteItems.find((item: RefinementListItem) => item.value === "true")?.count;

  const handleGetLocation = () => {
    const successCallback = (position: GeolocationPosition) => {
      onSetCoords({
        latitude: position.coords.latitude.toString(),
        longitude: position.coords.longitude.toString(),
        isApplied: false,
      });

      setLoadingNearMe(false);
    };

    const errorCallback = (error: GeolocationPositionError) => {
      setLoadingNearMe(false);
      return error;
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

    setLoadingNearMe(true);
  };

  const [numberOfJobsNearMe, setNumberOfJobsNearMe] = useState<number | string>("-");

  useEffect(() => {
    if (!coords) {
      return;
    }
    const fetchNumberOfJobsNearMe = async () => {
      const results = await algoliaClient
        .initIndex(process.env.NEXT_PUBLIC_ALGOLIA_INDEX || "test_search_index")
        .search("", {
          filters: `customerSlug:${customerSlug}`,
          aroundLatLng: `${coords.latitude}, ${coords.longitude}`,
          aroundRadius: 40000,
        });

      setNumberOfJobsNearMe(results.nbHits);
    };

    fetchNumberOfJobsNearMe();
  }, [setNumberOfJobsNearMe, coords, customerSlug]);

  const handleOnClick = (cardId: string) => {
    if (!latitude && !longitude) {
      handleGetLocation();
    } else if (cardId === "third-card") {
      onSetCoords(prev => ({
        ...prev,
        isApplied: true,
      }));
    }
  };

  const suggestionsCards: ISuggestionCardProps[] = [
    {
      backgroundColor: firstCardColor,
      borderStyle,
      text: firstCardText,
      number: (hasNoRemoteJobs ? fallbackCategoryCount : remoteJobsCount) || 0,
      icon: hasNoRemoteJobs ? (
        <DynamicIcon icon={listingSiteData?.fallbackIcon} />
      ) : (
        <HouseIcon style={{ margin: "0 auto" }} />
      ),
      cardId: "first-card",
      onFacetSelected: () => {
        if (hasNoRemoteJobs) {
          onRefine("categories", listingSiteData?.fallbackCategory || "");
          onSetSelectedCategories(prev => [...prev, listingSiteData?.fallbackCategory || ""]);
        } else {
          onRefine("isRemote", "true");
        }
      },
    },
    {
      backgroundColor: secondCardColor,
      borderStyle,
      text: translate("jobcard.suggestions.full_time_text"),
      number: isPartTimeItems[0]?.count || "",
      icon: <ClockIcon width="24" height="24" />,
      cardId: "second-card",
      onFacetSelected: () => {
        onSetSelectedJobTypes({
          isPartTime: false,
          isFullTime: true,
        });
        onRefine("isPartTime", "false");
      },
    },
    {
      backgroundColor: thirdCardColor,
      borderStyle,
      text: translate("suggestions.cards.near_me_text", "Near me"),
      number: Boolean(!latitude && !longitude && loadingNearMe) ? (
        <RefreshIcon className="mx-auto mb-1 animate-spin" />
      ) : (
        numberOfJobsNearMe
      ),
      icon: <LocationIcon />,
      cardId: "third-card",
      onFacetSelected: () => {
        clearRefinements();
        handleOnClick("third-card");
      },
    },
  ];

  return !showSuggestions ? (
    <>
      <div className={`${whiteText ? "text-white" : "text-dark-grey"} mt-5 text-lg font-medium md:mt-0`}>
        {translate("suggestions.cards.title")}
      </div>
      <SuggestionCardLayout cards={suggestionsCards} />
    </>
  ) : null;
};

export default Suggestions;
