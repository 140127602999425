import React, { FC } from "react";
import { Configure, InstantSearch, InstantSearchSSRProvider } from "react-instantsearch";
import { algoliaClient } from "lib/algoliasearch";
import { IListingSiteProps } from "pages/[customerSlug]";
import JobSearch from "./JobSearch";
import { useBrowserStorage } from "hooks/useBrowserStorage";
import { history } from "instantsearch.js/es/lib/routers/index.js";

export interface IDefaultCoords {
  latitude: string;
  longitude: string;
  isApplied: boolean;
}

export const DEFAULT_PROPS = {
  searchClient: algoliaClient,
  indexName: process.env.NEXT_PUBLIC_ALGOLIA_INDEX || "test_search_index",
};

export const DEFAULT_COORDS: IDefaultCoords = {
  latitude: "",
  longitude: "",
  isApplied: false,
};

const NEAR_ME_RADIUS = 40000;

const JobSearchProvider: FC<IListingSiteProps> = ({ serverState, url, customerSlug, listingSiteData }) => {
  const [coords, setCoords] = useBrowserStorage("coords", "localStorage", DEFAULT_COORDS);

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        {...DEFAULT_PROPS}
        routing={{
          router: history({
            getLocation() {
              if (typeof window === "undefined") {
                return new URL(url || "") as unknown as Location;
              }

              return window.location;
            },
          }),
        }}
        insights
      >
        <Configure
          hitsPerPage={12}
          filters={`customerSlug:${customerSlug}`}
          {...(coords.isApplied && {
            aroundLatLng: `${coords.latitude},${coords.longitude}`,
            aroundRadius: NEAR_ME_RADIUS,
          })}
          clickAnalytics
          analyticsTags={[`customerSlug:${customerSlug}`]}
        />
        <JobSearch
          coords={coords}
          onSetCoords={setCoords}
          listingSiteData={listingSiteData}
          customerSlug={customerSlug}
        />
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};

export default JobSearchProvider;
