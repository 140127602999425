import React, { FC, ReactElement } from "react";
import { Highlight } from "react-instantsearch";
import { IJobPostHit } from "./JobHits";
import { useIsInIframe } from "hooks/useIsInIframe";
import Link from "next/link";
import { DEFAULT_RECENT_VISIT } from "./JobSearch";
import { useBrowserStorage } from "hooks/useBrowserStorage";
import { handleNewRecentJob } from "utils/recentVisit";
import JobCardTitle from "components/jobcard/JobCardTitle";
import JobCardSubTitle from "components/jobcard/JobCardSubtitle";
import JobCardPubDate from "components/jobcard/JobPostPubDate";
import JobCardLocation from "components/jobcard/JobCardLocation";
import JobCardPartTime from "components/jobcard/JobCardPartTime";
import JobCardWrapper from "components/jobcard/JobCardWrapper";

interface IJobCardLinkWrapperProps {
  url: string;
  children: ReactElement;
}

const JobCardLinkWrapper: FC<IJobCardLinkWrapperProps> = ({ children, url }) => {
  const isIframe = useIsInIframe();
  return isIframe ? (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link href={url}>{children}</Link>
  );
};

interface IJobCardProps {
  jobPostHit: IJobPostHit;
  showCompanyName: boolean;
  sendAlgoliaHitEvent?: () => void;
  showBackground: boolean;
  borderStyle: string;
}
export interface IDefaultQueryId {
  value: string;
  jobPostId: string;
}

const DEFAULT_QUERY_ID: IDefaultQueryId = { value: "", jobPostId: "" };

const JobCard: FC<IJobCardProps> = ({ jobPostHit, sendAlgoliaHitEvent, showBackground, borderStyle }) => {
  const [recentVisit, setRecentVisit] = useBrowserStorage("recentVisit", "localStorage", DEFAULT_RECENT_VISIT);
  const [, setQueryId] = useBrowserStorage("queryId", "sessionStorage", DEFAULT_QUERY_ID);

  return (
    <JobCardLinkWrapper url={`${jobPostHit.landingPageUrl || ""}`}>
      <JobCardWrapper
        id={jobPostHit.__position}
        onClick={() => {
          setRecentVisit(handleNewRecentJob(recentVisit.jobs, jobPostHit.objectID));
          setQueryId({ value: jobPostHit.__queryID || "", jobPostId: jobPostHit.objectID || "" });
          sendAlgoliaHitEvent?.();
        }}
        showBackground={showBackground}
        borderStyle={borderStyle}
      >
        <>
          <JobCardTitle>
            <Highlight attribute="title" hit={jobPostHit} />
          </JobCardTitle>
          <div className="mb-3 w-full">
            <div className="grid grid-cols-2">
              <JobCardSubTitle employerName={jobPostHit.employerName || ""} showCompanyName={false}>
                <Highlight attribute="categories" hit={jobPostHit} />
              </JobCardSubTitle>
              <JobCardPubDate pubDate={jobPostHit.pubDate || ""} />
            </div>
          </div>
          <div className="flex">
            <JobCardPartTime isPartTime={jobPostHit.isPartTime} />
            <JobCardLocation isRemote={jobPostHit.isRemote} cities={jobPostHit.cities}>
              <Highlight attribute="cities" hit={jobPostHit} separator=" - " />
            </JobCardLocation>
          </div>
        </>
      </JobCardWrapper>
    </JobCardLinkWrapper>
  );
};

export default JobCard;
