import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import { useRef, useEffect, useCallback, FC } from "react";
import type { BaseHit, Hit } from "instantsearch.js";
import { useInfiniteHits } from "react-instantsearch";
import JobCard from "./JobCard";
import { ICustomer } from "api/customers";
import { IJobPost } from "api/jobPosts";
import { useTranslation } from "next-i18next";

export interface IJobPostHit extends Hit<BaseHit> {
  title: IJobPost["title"];
  locations: IJobPost["locations"];
  customerSlug: ICustomer["slug"];
  customerName: ICustomer["name"];
  pubDate?: IJobPost["pubDate"];
  isRemote: boolean;
  isPartTime: IJobPost["isPartTime"];
  employerName?: IJobPost["employerName"];
  categories: IJobPost["categories"];
  landingPageSlug: IJobPost["landingPageSlug"];
  cities: IJobPost["locations"][0]["city"][];
  _geo?: {
    lat: number;
    lng: number;
  };
}

interface IJobHitsProps {
  whiteText: boolean;
  showBackground: boolean;
  borderStyle: string;
}

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const JobHits: FC<IJobHitsProps> = ({ whiteText, showBackground, borderStyle }) => {
  const { t: translate } = useTranslation("listingsite");
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { hits, showMore, isLastPage, results, sendEvent } = useInfiniteHits({ cache: sessionStorageCache });
  const textColor = whiteText ? "text-white" : "text-dark-grey";
  const availablePositionsTranslated = translate("joblist.available_positions_text");

  const handleSentinelIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isLastPage) {
          showMore && showMore();
        }
      });
    },
    [isLastPage, showMore]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleSentinelIntersection, {});
    if (scrollRef?.current) {
      observer.observe(scrollRef.current);
    }

    return () => observer.disconnect();
  }, [handleSentinelIntersection]);

  return (
    <>
      <div className={`flex ${hits.length === 0 || hits.length < 7 ? "h-screen" : ""} flex-col gap-4`}>
        {hits?.length ? (
          <>
            <span className={`text-lg font-semibold leading-[22px] ${textColor}`}>
              {results?.nbHits
                ? `${results.nbHits} ${availablePositionsTranslated.toLowerCase()}`
                : availablePositionsTranslated}
            </span>
            {hits.map(hit => (
              // Explicitly cast jobPostHit here because sessionStorageCache don't support generics and throws an error if useInfiniteHits returns IJobPostHit[]
              <JobCard
                key={hit.objectID}
                jobPostHit={hit as IJobPostHit}
                showCompanyName={false}
                sendAlgoliaHitEvent={() => sendEvent("click", hit, `job card clicked`)}
                showBackground={showBackground}
                borderStyle={borderStyle}
              />
            ))}
          </>
        ) : (
          <span className={`${textColor}`}>{translate("jobhits.no_jobs", "No jobs found")}</span>
        )}
      </div>
      <div ref={scrollRef} className="h-4 w-4" />
    </>
  );
};

export default JobHits;
