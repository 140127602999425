import { useBrowserStorage } from "hooks/useBrowserStorage";
import React, { FC, useCallback, useEffect, useState } from "react";
import { DEFAULT_RECENT_VISIT, IAlgoliaId } from "./JobSearch";
import { algoliaClient } from "lib/algoliasearch";
import JobCard from "./JobCard";
import { IJobPostHit } from "./JobHits";
import algoliaAnalytics from "search-insights";
import { useTranslation } from "next-i18next";

interface IRecentVisitProps {
  whiteText: boolean;
  customerSlug: string;
  borderStyle: string;
  showBackground: boolean;
}

const RecentVisit: FC<IRecentVisitProps> = ({ whiteText, customerSlug, borderStyle, showBackground }) => {
  const { t: translate } = useTranslation("listingsite");
  const recentVisitTranslated = translate("first.page.recent_visit_text");
  const latestJobsTranslated = translate("first.page.latest_jobs_text");

  const [recentVisit] = useBrowserStorage("recentVisit", "localStorage", DEFAULT_RECENT_VISIT);
  const [recentJobs, setRecentJobs] = useState<IJobPostHit[]>();
  const [title, setTitle] = useState<string>(latestJobsTranslated);

  const [algoliaId] = useBrowserStorage<IAlgoliaId>("algoliaId", "sessionStorage");
  algoliaAnalytics("init", {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || "",
  });

  const fetchRecentJobs = useCallback(
    async (jobs: string[]) => {
      const results = await algoliaClient
        .initIndex(process.env.NEXT_PUBLIC_ALGOLIA_REPLICA_INDEX || "test_sort_by_pubDate")
        .search("", {
          facetFilters: [jobs],
          filters: `customerSlug:${customerSlug}`,
          hitsPerPage: 5,
          analytics: true,
          clickAnalytics: true,
        });

      Boolean(jobs?.length === 0) ? setTitle(latestJobsTranslated) : setTitle(recentVisitTranslated);
      setRecentJobs(results.hits as IJobPostHit[]);
    },
    [customerSlug, latestJobsTranslated, recentVisitTranslated]
  );

  useEffect(() => {
    fetchRecentJobs(recentVisit.jobs.map(j => `objectID:${j}`));
  }, [fetchRecentJobs, recentVisit.jobs]);

  if (recentJobs?.length === 0) fetchRecentJobs([]);

  return (
    <>
      <h3 className={`${whiteText ? "text-white" : "text-dark-grey"} text-lg font-medium`}>{title}</h3>
      <div className="flex flex-col gap-4">
        {recentJobs?.map(hit => {
          return (
            <JobCard
              key={hit.objectID}
              jobPostHit={hit as IJobPostHit}
              showCompanyName={false}
              borderStyle={borderStyle}
              showBackground={showBackground}
              sendAlgoliaHitEvent={() => {
                algoliaAnalytics("clickedObjectIDs", {
                  userToken: algoliaId.value,
                  index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX || "test_search_index",
                  eventName: `${title.toLowerCase().replace(/\s+/g, "-")}-job-card`,
                  objectIDs: [hit.objectID],
                });
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default RecentVisit;
